<template>
  <b-modal
    :title="name"
    id="group-membership-modal"
    lazy
    :busy="isBusy"
    size="xl"
  >
    <b-tabs pills v-model="tabIndex" nav-class="nav-primary">
      <b-tab lazy title="In Group">
        <TeacherMembershipTable :reload="reload"></TeacherMembershipTable>
      </b-tab>
      <b-tab lazy title="Available">
        <TeacherMembershipAvailableTable
          :reload="reload"
        ></TeacherMembershipAvailableTable>
      </b-tab>
    </b-tabs>

    <!-- begin: Modal Footer   -->
    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="secondary"
        v-on:click="destroy"
        v-if="tabIndex === 0"
        v-bind:class="{
          'spinner spinner-light spinner-right': isBusy === true,
        }"
        >Delete
      </b-button>
      <b-button
        variant="primary"
        v-on:click="save"
        v-if="tabIndex === 1"
        v-bind:class="{
          'spinner spinner-light spinner-right': isBusy === true,
        }"
        >Save
      </b-button>
      <b-button
        :disabled="isBusy"
        variant="light"
        class="btn-hover-danger"
        @click="cancel"
        >{{ $t("BUTTON.CANCEL") }}
      </b-button>
    </template>
    <!-- end: Modal Footer   -->
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import TeacherMembershipTable from "@/modules/school/components/teacher/group/membership/TeacherMembershipTable";
import TeacherMembershipAvailableTable from "@/modules/school/components/teacher/group/membership/TeacherMembershipAvailableTable";

export default {
  name: "TeacherMembershipModal",
  components: { TeacherMembershipAvailableTable, TeacherMembershipTable },
  data: function () {
    return {
      tabIndex: 0,
      isBusy: false,
      reload: false,
    };
  },
  computed: {
    ...mapGetters({
      group: "fetchGroup",
      // isEmptyList: "getTeacherGroupMemberInListEmpty",
    }),
    name() {
      return this.group.name ? this.group.name + " Teacher Group" : "";
    },
  },
  methods: {
    save() {
      this.isBusy = true;
      this.$store
        .dispatch("saveTeacherGroupMembers", {
          id: this.group.id,
        })
        .then(() =>
          this.$bvModal.msgBoxOk(this.$t("GROUP.MEMBERSHIP.ADDSUCCESS"))
        )
        .finally(() => {
          this.isBusy = false;
          this.reload = !this.reload;
        });
    },
    destroy() {
      this.isBusy = true;
      this.$store
        .dispatch("deleteTeacherGroupMembers", {
          id: this.group.id,
        })
        .then(() =>
          this.$bvModal.msgBoxOk(this.$t("GROUP.MEMBERSHIP.DELETESUCCESS"))
        )
        .finally(() => {
          this.isBusy = false;
          this.reload = !this.reload;
        });
    },
  },
};
</script>

<style scoped></style>
