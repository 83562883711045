<template>
  <div class="d-flex flex-column">
    <TeacherGroupScheduleControl></TeacherGroupScheduleControl>
    <TeacherGroupScheduleForm></TeacherGroupScheduleForm>
  </div>
</template>

<script>
import TeacherGroupScheduleForm from "@/modules/school/components/teacher/group/schedule/TeacherGroupScheduleForm";
import TeacherGroupScheduleControl from "@/modules/school/components/teacher/group/schedule/TeacherGroupScheduleControl";

export default {
  name: "TeacherGroupSchedule",
  components: { TeacherGroupScheduleControl, TeacherGroupScheduleForm },
};
</script>

<style scoped></style>
