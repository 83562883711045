<template>
  <b-form v-on:submit.prevent="save">
    <div class="d-flex flex-lg-row flex-column mt-5">
      <div class="flex-grow-1 mr-5">
        <!-- begin: Name    -->
        <b-form-group
          :label="$t('GROUP.GENERAL.NAME')"
          label-cols-sm="4"
          label-cols-lg="3"
        >
          <b-form-input
            v-model="form.name"
            :state="validateState('name')"
          ></b-form-input>
        </b-form-group>
        <!-- end: Name    -->

        <!-- begin: Description    -->
        <b-form-group
          :label="$t('GROUP.GENERAL.DESCRIPTION')"
          label-cols-sm="4"
          label-cols-lg="3"
        >
          <b-form-textarea
            v-model="form.description"
            :state="validateState('description')"
          ></b-form-textarea>
        </b-form-group>
        <!-- end: Description    -->

        <b-form-group label="Disabled Late?">
          <b-form-checkbox
            inline
            v-model="$v.form.mode.$model"
            value="1"
            unchecked-value="0"
            >{{ $t("BUTTON.YES") }}</b-form-checkbox
          >
        </b-form-group>
      </div>
    </div>
  </b-form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { minLength, required } from "vuelidate/lib/validators";
import { mapGetters, mapState } from "vuex";

export default {
  name: "TeacherGroupEditForm",
  mixins: [validationMixin],
  props: {
    group: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        mode: null,
      },
    };
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
      description: {
        required,
        minLength: minLength(3),
      },
      mode: { required },
    },
  },
  beforeMount() {
    const group = this.getTeacherGroupInformation;
    this.form.name = group.name;
    this.form.description = group.description;
    this.form.mode = group.mode === false ? 0 : 1;
    this.form.id = this.getTeacherGroupInformation.id;
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    save() {
      console.log("Teacher Group Form submission");
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const newForm = {
        id: this.getTeacherGroupInformation.id,
        name: this.form.name,
        description: this.form.description,
        senior_mode: this.form.mode,
        group_type_id: 1,
      };

      this.$store
        .dispatch("updateTeacherGroupForm", newForm)
        .then(() => {
          // Success
          this.$emit("success", true);
          this.$bvModal.msgBoxOk(
            this.$t("GROUP.GENERAL.FORM.SUCCESS", {
              group: this.getTeacherGroupInformation.name,
            }),
            {
              title: this.$t("GROUP.GENERAL.FORM.SUCCESS_TITLE"),
            }
          );
        })
        .catch(() => {
          // Display failed
          this.$bvModal.msgBoxOk(
            this.$t("GROUP.GENERAL.FORM.FAILED", {
              group: this.getTeacherGroupInformation.name,
            }),
            {
              okVariant: "danger",
              title: this.$t("GROUP.GENERAL.FORM.FAILED_TITLE"),
            }
          );
        });
    },
  },
  computed: {
    ...mapState({
      isBusy: (state) =>
        state.CompanyModule.EmployeeGroupStore.EmployeeGroupForm.isBusy,
    }),
    ...mapGetters([
      "getTeacherGroupInformation",
      "getHeldTeacherGroupSchedule",
      "checkUpdateTeacherGroupScheduleEvent",
    ]),
  },
  watch: {
    checkUpdateTeacherGroupScheduleEvent(newState, oldState) {
      console.log("Component", newState, oldState);
      this.save();
    },
  },
};
</script>

<style scoped></style>
